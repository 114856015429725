<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="480" height="178.53" viewBox="0 0 650 178.53">
    <g id="Camada_2" data-name="Camada 2">
    <g id="Logo_DarkColor">
      <g id="Name">
        <path d="M227.35,127.49V115.65a57.82,57.82,0,0,1-17.75,10.23,60.19,60.19,0,0,1-20.72,3.58A61.59,61.59,0,0,1,164,124.4a62.32,62.32,0,0,1-33.54-34.27,66.23,66.23,0,0,1-4.93-25.52,65.34,65.34,0,0,1,4.93-25.4,63.86,63.86,0,0,1,13.44-20.47A64.36,64.36,0,0,1,209.35,3.21a64.48,64.48,0,0,1,17.75,9.12V2h24.66V127.49ZM149.68,64.36q0,18.49,12.33,31.07T192.34,108a48.31,48.31,0,0,0,19.6-4A41.92,41.92,0,0,0,227.1,93V36a43.12,43.12,0,0,0-15.28-10.85,48.59,48.59,0,0,0-19.48-3.94q-18,0-30.33,12.33T149.68,64.36Z" style="fill: #0f1821"/>
        <path d="M272.23,178.53V2h24.41V14.06A52.59,52.59,0,0,1,314.15,3.58a60.85,60.85,0,0,1,21-3.58A61.59,61.59,0,0,1,360,5.06a62.61,62.61,0,0,1,33.54,34.15,65.34,65.34,0,0,1,4.93,25.4,66.23,66.23,0,0,1-4.93,25.52,63.43,63.43,0,0,1-13.44,20.59,62.75,62.75,0,0,1-20.34,13.68,64.81,64.81,0,0,1-45.25,1.85,60.47,60.47,0,0,1-17.63-9.12v61.4ZM331.9,21.45a49.12,49.12,0,0,0-19.72,3.95,41.64,41.64,0,0,0-15.29,11.1V93.21a40.44,40.44,0,0,0,15.16,10.85,50.07,50.07,0,0,0,19.85,4q18,0,30.21-12.33t12.21-30.83q0-18.49-12.33-30.94A40.76,40.76,0,0,0,331.9,21.45Z" style="fill: #0f1821"/>
        <path d="M413.28,178.53V2h24.41V14.06A52.49,52.49,0,0,1,455.2,3.58a60.85,60.85,0,0,1,21-3.58,61.62,61.62,0,0,1,24.9,5.06A62.61,62.61,0,0,1,534.6,39.21a65.34,65.34,0,0,1,4.93,25.4,66.23,66.23,0,0,1-4.93,25.52,63.43,63.43,0,0,1-13.44,20.59,62.75,62.75,0,0,1-20.34,13.68,64.81,64.81,0,0,1-45.25,1.85,60.35,60.35,0,0,1-17.63-9.12v61.4ZM473,21.45a49.17,49.17,0,0,0-19.73,3.95,41.6,41.6,0,0,0-15.28,11.1V93.21a40.44,40.44,0,0,0,15.16,10.85A50,50,0,0,0,473,108q18,0,30.21-12.33t12.2-30.83q0-18.49-12.33-30.94A40.73,40.73,0,0,0,473,21.45Z" style="fill: #0f1821"/>
        <path d="M542.49,111.21,555.8,94.94a83.17,83.17,0,0,0,20.1,11.71,56,56,0,0,0,20.84,4.07q13.31,0,21.7-5.18T626.82,92a12.92,12.92,0,0,0-4.93-10.61Q617,77.44,606.6,76l-22.69-3.21q-18.49-2.7-28-11.46T546.43,38q0-17,13.56-27.5T595.5,0a86.52,86.52,0,0,1,26.27,4A92,92,0,0,1,646.06,16L633.48,32.3a80.24,80.24,0,0,0-20.1-10,64.48,64.48,0,0,0-19.6-3.09q-11.35,0-18.25,4.69t-6.91,12.33q0,6.9,4.81,10.48T589.34,52L612,55.24Q630.51,58,640.26,66.83T650,90.25a31.59,31.59,0,0,1-4.07,15.66,38.93,38.93,0,0,1-11.1,12.45,56.91,56.91,0,0,1-16.64,8.26,67.35,67.35,0,0,1-20.71,3.09A96.61,96.61,0,0,1,567,125,69.5,69.5,0,0,1,542.49,111.21Z" style="fill: #0f1821"/>
        <g>
          <rect y="26.63" width="24.66" height="100.85" style="fill: #0f1821"/>
          <path d="M86.8,2H49.32A24.66,24.66,0,0,0,24.66,26.63H86.8V127.49h24.66V2Z" style="fill: #0f1821"/>
        </g>
      </g>
    </g>
  </g>
</svg>

</template>
<script>
export default {
    name: 'Logo',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>