<template>
<svg width="188" height="63" viewBox="0 0 188 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1294:4857)">
<path d="M173.029 0.00020475H14.9796C14.4035 0.00020475 13.8343 0.00020475 13.2597 0.00335475C12.7787 0.00650475 12.3016 0.0156555 11.816 0.0233573C10.761 0.0358026 9.70856 0.128845 8.66772 0.301676C7.62834 0.478269 6.62152 0.811197 5.68132 1.2892C4.74226 1.77125 3.88421 2.39762 3.13831 3.14559C2.38849 3.89146 1.76346 4.75358 1.28725 5.69881C0.809727 6.64215 0.47861 7.65303 0.305336 8.69654C0.130416 9.73872 0.0362891 10.7929 0.0238016 11.8497C0.00922215 12.3326 0.00768251 12.8171 0 13.3V49.705C0.00768251 50.194 0.00922215 50.6678 0.0238016 51.157C0.0362929 52.2137 0.130419 53.2679 0.305336 54.31C0.478131 55.3541 0.809268 56.3656 1.28725 57.3093C1.76325 58.2514 2.38837 59.1101 3.13831 59.8518C3.88139 60.6031 4.73998 61.2299 5.68132 61.7082C6.62152 62.1875 7.62823 62.5224 8.66772 62.7018C9.70874 62.8732 10.7611 62.9663 11.816 62.9802C12.3016 62.9909 12.7787 62.9971 13.2597 62.9971C13.8343 63.0002 14.4035 63.0002 14.9796 63.0002H173.029C173.593 63.0002 174.167 63.0002 174.732 62.9971C175.211 62.9971 175.702 62.9909 176.18 62.9802C177.233 62.967 178.284 62.8739 179.322 62.7018C180.365 62.5212 181.376 62.1863 182.32 61.7082C183.261 61.2296 184.119 60.6028 184.861 59.8518C185.609 59.1072 186.235 58.2492 186.717 57.3093C187.192 56.3649 187.52 55.3535 187.69 54.31C187.865 53.2677 187.963 52.2138 187.982 51.157C187.988 50.6678 187.988 50.194 187.988 49.705C188 49.1328 188 48.5638 188 47.9823V15.0196C188 14.4428 188 13.8707 187.988 13.3C187.988 12.8171 187.988 12.3326 187.982 11.8496C187.963 10.7928 187.865 9.7388 187.69 8.69647C187.519 7.65357 187.191 6.64277 186.717 5.69874C185.748 3.80414 184.21 2.26195 182.32 1.28906C181.376 0.812223 180.365 0.479383 179.322 0.301534C178.284 0.127942 177.233 0.034867 176.18 0.0231368C175.702 0.0154508 175.211 0.00622125 174.732 0.00315C174.167 0 173.593 0 173.029 0V0.00020475Z" fill="#A6A6A6"/>
<path d="M13.2669 61.6227C12.7882 61.6227 12.3211 61.6165 11.8462 61.6058C10.8624 61.593 9.88086 61.5071 8.90967 61.3489C8.0041 61.1926 7.12684 60.9017 6.30683 60.486C5.49434 60.0737 4.75328 59.5331 4.11205 58.8848C3.46154 58.2442 2.91995 57.5013 2.50876 56.6853C2.09304 55.8641 1.80534 54.9839 1.65568 54.0753C1.49405 53.0989 1.40661 52.1117 1.39409 51.1221C1.38413 50.79 1.37109 49.684 1.37109 49.684V13.3006C1.37109 13.3006 1.38498 12.2116 1.39417 11.8917C1.40615 10.9037 1.49309 9.91801 1.65423 8.94321C1.80417 8.0321 2.0921 7.14931 2.50804 6.3254C2.91772 5.51004 3.4563 4.76651 4.10289 4.12363C4.74876 3.47464 5.49219 2.93123 6.30606 2.51324C7.12418 2.09883 7.99979 1.81004 8.90353 1.65653C9.8779 1.49677 10.8628 1.4104 11.8501 1.39814L13.2677 1.37891H174.724L176.158 1.39891C177.137 1.41056 178.113 1.49616 179.078 1.65499C179.991 1.81042 180.876 2.10123 181.703 2.51785C183.334 3.36023 184.66 4.69296 185.497 6.32924C185.906 7.14747 186.19 8.02305 186.338 8.92629C186.501 9.90909 186.592 10.9025 186.611 11.8986C186.616 12.3447 186.616 12.8238 186.616 13.3006C186.628 13.8912 186.628 14.4534 186.628 15.0202V47.9828C186.628 48.555 186.628 49.1134 186.616 49.6763C186.616 50.1885 186.616 50.6577 186.609 51.1406C186.591 52.1189 186.501 53.0946 186.341 54.0598C186.194 54.975 185.908 55.8621 185.493 56.6901C185.078 57.4971 184.54 58.2337 183.897 58.8725C183.255 59.5243 182.513 60.068 181.698 60.483C180.873 60.9019 179.99 61.1938 179.078 61.3489C178.107 61.5079 177.125 61.5938 176.141 61.6058C175.681 61.6165 175.199 61.6227 174.731 61.6227L173.028 61.6258L13.2669 61.6227Z" fill="white"/>
<path d="M38.9136 31.9739C38.9304 30.6593 39.2788 29.3704 39.9261 28.227C40.5734 27.0836 41.4987 26.123 42.6159 25.4344C41.9062 24.4183 40.9699 23.582 39.8814 22.9921C38.7929 22.4022 37.5822 22.0748 36.3455 22.0361C33.7073 21.7584 31.1497 23.6187 29.8053 23.6187C28.4349 23.6187 26.365 22.0636 24.1361 22.1096C22.6944 22.1563 21.2893 22.5766 20.0578 23.3295C18.8263 24.0825 17.8103 25.1424 17.1089 26.406C14.0704 31.6797 16.3368 39.4304 19.2474 43.6933C20.7036 45.7807 22.4055 48.1125 24.6324 48.0298C26.8116 47.9392 27.6255 46.6367 30.2559 46.6367C32.8619 46.6367 33.6255 48.0298 35.8976 47.9772C38.2359 47.9391 39.7092 45.8805 41.1143 43.7733C42.1606 42.2859 42.9657 40.6421 43.4999 38.9026C42.1414 38.3266 40.982 37.3624 40.1665 36.1303C39.3509 34.8982 38.9151 33.4526 38.9136 31.9739Z" fill="#263238"/>
<path d="M34.622 19.2321C35.897 17.6977 36.5251 15.7255 36.373 13.7344C34.4252 13.9395 32.6259 14.8728 31.3337 16.3483C30.7019 17.0691 30.218 17.9077 29.9097 18.8161C29.6014 19.7245 29.4747 20.6849 29.5369 21.6424C30.5111 21.6525 31.475 21.4408 32.3558 21.0233C33.2367 20.6058 34.0115 19.9933 34.622 19.2321Z" fill="#263238"/>
<path d="M66.46 42.7446H59.0236L57.2377 48.031H54.0879L61.1316 28.4727H64.4041L71.4478 48.031H68.2443L66.46 42.7446ZM59.7937 40.3052H65.6884L62.7825 31.7257H62.7012L59.7937 40.3052Z" fill="#263238"/>
<path d="M86.6603 40.9015C86.6603 45.3328 84.2945 48.1798 80.7243 48.1798C79.8199 48.2272 78.9205 48.0183 78.129 47.5771C77.3375 47.1359 76.686 46.4802 76.2489 45.685H76.1814V52.7479H73.2617V33.771H76.0878V36.1427H76.1415C76.5986 35.3513 77.2612 34.699 78.0587 34.2551C78.8563 33.8112 79.7589 33.5923 80.6706 33.6218C84.2806 33.6218 86.6603 36.4826 86.6603 40.9015ZM83.6593 40.9015C83.6593 38.0146 82.1711 36.1166 79.9004 36.1166C77.6696 36.1166 76.1691 38.0545 76.1691 40.9015C76.1691 43.7747 77.6696 45.6988 79.9004 45.6988C82.1711 45.6988 83.6593 43.8147 83.6593 40.9015Z" fill="#263238"/>
<path d="M102.315 40.9015C102.315 45.3328 99.9488 48.1797 96.3786 48.1797C95.4742 48.2272 94.5748 48.0183 93.7833 47.5771C92.9918 47.1359 92.3403 46.4801 91.9032 45.6849H91.8357V52.7478H88.916V33.771H91.7421V36.1427H91.7958C92.2529 35.3513 92.9155 34.699 93.713 34.2551C94.5105 33.8111 95.4131 33.5923 96.3249 33.6218C99.935 33.6218 102.315 36.4826 102.315 40.9015ZM99.3136 40.9015C99.3136 38.0146 97.8254 36.1165 95.5547 36.1165C93.3239 36.1165 91.8234 38.0545 91.8234 40.9015C91.8234 43.7747 93.3239 45.6988 95.5547 45.6988C97.8254 45.6988 99.3136 43.8147 99.3136 40.9015H99.3136Z" fill="#263238"/>
<path d="M112.663 42.5815C112.879 44.521 114.758 45.7945 117.327 45.7945C119.788 45.7945 121.558 44.521 121.558 42.7722C121.558 41.2541 120.49 40.3451 117.962 39.7221L115.433 39.1115C111.851 38.244 110.188 36.5644 110.188 33.8389C110.188 30.4644 113.121 28.1465 117.287 28.1465C121.409 28.1465 124.235 30.4644 124.33 33.8389H121.383C121.207 31.8871 119.597 30.7089 117.245 30.7089C114.893 30.7089 113.284 31.901 113.284 33.6359C113.284 35.0187 114.312 35.8323 116.826 36.4552L118.976 36.9843C122.979 37.9333 124.642 39.5452 124.642 42.406C124.642 46.0651 121.735 48.3569 117.11 48.3569C112.784 48.3569 109.863 46.119 109.674 42.5814L112.663 42.5815Z" fill="#263238"/>
<path d="M130.943 30.3965V33.771H133.648V36.0889H130.943V43.9501C130.943 45.1713 131.485 45.7404 132.674 45.7404C132.995 45.7348 133.315 45.7122 133.634 45.6727V47.9768C133.099 48.0769 132.556 48.1222 132.012 48.1121C129.133 48.1121 128.01 47.0278 128.01 44.2623V36.0889H125.941V33.771H128.01V30.3965H130.943Z" fill="#263238"/>
<path d="M135.215 40.9016C135.215 36.415 137.851 33.5957 141.961 33.5957C146.085 33.5957 148.709 36.415 148.709 40.9016C148.709 45.4005 146.099 48.2075 141.961 48.2075C137.825 48.2075 135.215 45.4005 135.215 40.9016ZM145.734 40.9016C145.734 37.8239 144.327 36.0074 141.961 36.0074C139.595 36.0074 138.19 37.8377 138.19 40.9016C138.19 43.9916 139.595 45.7943 141.961 45.7943C144.327 45.7943 145.734 43.9916 145.734 40.9016H145.734Z" fill="#263238"/>
<path d="M151.115 33.7708H153.9V36.1979H153.967C154.156 35.4399 154.599 34.77 155.222 34.3008C155.845 33.8316 156.61 33.5918 157.389 33.6216C157.725 33.6204 158.061 33.6571 158.389 33.7308V36.4686C157.964 36.3385 157.521 36.2788 157.077 36.2917C156.653 36.2745 156.23 36.3495 155.838 36.5115C155.445 36.6736 155.092 36.9188 154.803 37.2305C154.514 37.5422 154.296 37.9129 154.163 38.3171C154.031 38.7214 153.987 39.1496 154.035 39.5725V48.0304H151.115L151.115 33.7708Z" fill="#263238"/>
<path d="M171.85 43.8424C171.458 46.431 168.943 48.2075 165.726 48.2075C161.588 48.2075 159.02 45.4282 159.02 40.9693C159.02 36.4965 161.602 33.5957 165.603 33.5957C169.538 33.5957 172.013 36.3058 172.013 40.6294V41.6322H161.967V41.8091C161.92 42.3339 161.986 42.8625 162.159 43.36C162.332 43.8574 162.609 44.3123 162.971 44.6943C163.333 45.0763 163.772 45.3767 164.258 45.5757C164.745 45.7746 165.268 45.8675 165.793 45.8481C166.483 45.9129 167.176 45.7527 167.767 45.3913C168.359 45.03 168.819 44.4867 169.078 43.8424L171.85 43.8424ZM161.981 39.5865H169.092C169.118 39.1147 169.047 38.6425 168.882 38.1996C168.718 37.7567 168.464 37.3526 168.136 37.0128C167.809 36.673 167.414 36.4047 166.979 36.2248C166.543 36.0449 166.074 35.9573 165.603 35.9674C165.128 35.9646 164.656 36.0562 164.216 36.2369C163.776 36.4176 163.376 36.6839 163.04 37.0204C162.703 37.3568 162.436 37.7568 162.254 38.1973C162.072 38.6377 161.979 39.1099 161.981 39.5866V39.5865Z" fill="#263238"/>
<path d="M59.4271 13.7529C60.0392 13.7088 60.6534 13.8015 61.2255 14.0243C61.7975 14.247 62.3131 14.5943 62.7351 15.041C63.157 15.4877 63.4748 16.0227 63.6656 16.6075C63.8563 17.1922 63.9152 17.8121 63.838 18.4225C63.838 21.4248 62.2194 23.1506 59.4271 23.1506H56.041V13.7529H59.4271ZM57.497 21.8216H59.2644C59.7018 21.8478 60.1396 21.7759 60.5457 21.6111C60.9519 21.4464 61.3163 21.1929 61.6125 20.8691C61.9086 20.5453 62.129 20.1594 62.2576 19.7395C62.3862 19.3196 62.4198 18.8762 62.3559 18.4416C62.4152 18.0088 62.3782 17.5681 62.2476 17.1513C62.117 16.7344 61.8961 16.3517 61.6006 16.0305C61.3052 15.7093 60.9425 15.4576 60.5386 15.2935C60.1347 15.1293 59.6996 15.0567 59.2644 15.0809H57.497V21.8216Z" fill="#263238"/>
<path d="M65.4826 19.5997C65.4381 19.1336 65.4913 18.6634 65.6387 18.2191C65.7861 17.7749 66.0246 17.3664 66.3387 17.0199C66.6528 16.6735 67.0357 16.3966 67.4628 16.2072C67.8899 16.0178 68.3518 15.9199 68.8188 15.9199C69.2858 15.9199 69.7477 16.0178 70.1748 16.2072C70.6019 16.3966 70.9848 16.6735 71.2989 17.0199C71.613 17.3664 71.8514 17.7749 71.9989 18.2191C72.1463 18.6634 72.1995 19.1336 72.155 19.5997C72.2003 20.0663 72.1478 20.5372 72.0007 20.9821C71.8537 21.4271 71.6154 21.8363 71.3012 22.1835C70.987 22.5306 70.6038 22.808 70.1763 22.9979C69.7488 23.1877 69.2864 23.2858 68.8188 23.2858C68.3512 23.2858 67.8888 23.1877 67.4613 22.9979C67.0337 22.808 66.6506 22.5306 66.3364 22.1835C66.0222 21.8363 65.7839 21.4271 65.6368 20.9821C65.4898 20.5372 65.4373 20.0663 65.4826 19.5997ZM70.7189 19.5997C70.7189 18.0624 70.0301 17.1634 68.8211 17.1634C67.6075 17.1634 66.9248 18.0624 66.9248 19.5997C66.9248 21.1494 67.6076 22.0414 68.8211 22.0414C70.0301 22.0414 70.719 21.1432 70.719 19.5997H70.7189Z" fill="#263238"/>
<path d="M81.0255 23.1507H79.5772L78.1151 17.9274H78.0046L76.5486 23.1507H75.1141L73.1641 16.0586H74.5802L75.8475 21.4703H75.9518L77.4063 16.0586H78.7457L80.2001 21.4703H80.3106L81.5717 16.0586H82.9679L81.0255 23.1507Z" fill="#263238"/>
<path d="M84.6074 16.0579H85.9514V17.1846H86.0558C86.2327 16.7799 86.5312 16.4407 86.9097 16.2142C87.2881 15.9877 87.7276 15.8852 88.1669 15.921C88.5111 15.8951 88.8567 15.9471 89.1782 16.0733C89.4996 16.1994 89.7886 16.3965 90.0238 16.6498C90.2589 16.9031 90.4342 17.2063 90.5367 17.5367C90.6391 17.8672 90.666 18.2165 90.6155 18.5589V23.15H89.2194V18.9103C89.2194 17.7706 88.7253 17.2038 87.6928 17.2038C87.4591 17.1929 87.2258 17.2328 87.0089 17.3207C86.792 17.4086 86.5966 17.5426 86.4362 17.7133C86.2757 17.884 86.154 18.0874 86.0793 18.3097C86.0047 18.532 85.9788 18.7678 86.0036 19.0011V23.1501H84.6074L84.6074 16.0579Z" fill="#263238"/>
<path d="M92.8398 13.2891H94.236V23.1497H92.8398V13.2891Z" fill="#263238"/>
<path d="M96.1759 19.5998C96.1315 19.1337 96.1847 18.6635 96.3321 18.2192C96.4796 17.7749 96.7181 17.3664 97.0322 17.02C97.3464 16.6735 97.7293 16.3967 98.1564 16.2072C98.5835 16.0178 99.0454 15.9199 99.5125 15.9199C99.9796 15.9199 100.441 16.0178 100.869 16.2072C101.296 16.3967 101.679 16.6735 101.993 17.02C102.307 17.3664 102.545 17.7749 102.693 18.2192C102.84 18.6635 102.894 19.1337 102.849 19.5998C102.894 20.0664 102.842 20.5373 102.695 20.9823C102.548 21.4273 102.309 21.8365 101.995 22.1836C101.681 22.5308 101.298 22.8082 100.87 22.998C100.443 23.1878 99.9801 23.2859 99.5125 23.2859C99.0449 23.2859 98.5825 23.1878 98.1549 22.998C97.7274 22.8082 97.3442 22.5308 97.03 22.1836C96.7157 21.8365 96.4774 21.4273 96.3303 20.9823C96.1832 20.5373 96.1306 20.0664 96.1759 19.5998ZM101.412 19.5998C101.412 18.0625 100.723 17.1635 99.5144 17.1635C98.3008 17.1635 97.6181 18.0625 97.6181 19.5999C97.6181 21.1495 98.3009 22.0416 99.5144 22.0416C100.723 22.0416 101.412 21.1433 101.412 19.5998H101.412Z" fill="#263238"/>
<path d="M104.32 21.1433C104.32 19.8666 105.268 19.1307 106.952 19.0261L108.868 18.9153V18.3032C108.868 17.5541 108.374 17.1312 107.419 17.1312C106.64 17.1312 106.1 17.418 105.945 17.9194H104.593C104.736 16.7013 105.879 15.9199 107.484 15.9199C109.258 15.9199 110.258 16.8051 110.258 18.3032V23.1489H108.914V22.1522H108.803C108.579 22.5098 108.264 22.8012 107.891 22.9968C107.518 23.1925 107.099 23.2853 106.678 23.2658C106.381 23.2967 106.081 23.265 105.797 23.1725C105.513 23.0801 105.252 22.929 105.03 22.729C104.807 22.529 104.63 22.2846 104.507 22.0115C104.385 21.7383 104.321 21.4426 104.32 21.1433ZM108.868 20.5372V19.9443L107.14 20.0551C106.166 20.1204 105.724 20.4526 105.724 21.0779C105.724 21.7162 106.276 22.0876 107.036 22.0876C107.259 22.1102 107.483 22.0877 107.697 22.0214C107.911 21.9551 108.109 21.8464 108.28 21.7017C108.451 21.5571 108.591 21.3795 108.692 21.1794C108.793 20.9793 108.853 20.761 108.868 20.5372Z" fill="#263238"/>
<path d="M112.092 19.6006C112.092 17.3596 113.241 15.94 115.028 15.94C115.47 15.9196 115.909 16.0257 116.293 16.246C116.678 16.4664 116.991 16.7918 117.198 17.1842H117.302V13.2891H118.698V23.1497H117.36V22.0292H117.25C117.027 22.419 116.703 22.7404 116.311 22.9585C115.92 23.1766 115.476 23.2831 115.028 23.2666C113.229 23.2667 112.092 21.847 112.092 19.6006ZM113.534 19.6006C113.534 21.1049 114.241 22.01 115.424 22.01C116.601 22.01 117.328 21.0918 117.328 19.6068C117.328 18.1287 116.593 17.1974 115.424 17.1974C114.249 17.1974 113.534 18.1087 113.534 19.6006H113.534Z" fill="#263238"/>
<path d="M124.475 19.5997C124.43 19.1336 124.483 18.6634 124.631 18.2191C124.778 17.7749 125.017 17.3664 125.331 17.0199C125.645 16.6735 126.028 16.3966 126.455 16.2072C126.882 16.0178 127.344 15.9199 127.811 15.9199C128.278 15.9199 128.74 16.0178 129.167 16.2072C129.594 16.3966 129.977 16.6735 130.291 17.0199C130.605 17.3664 130.844 17.7749 130.991 18.2191C131.138 18.6634 131.192 19.1336 131.147 19.5997C131.193 20.0663 131.14 20.5372 130.993 20.9821C130.846 21.4271 130.608 21.8363 130.293 22.1835C129.979 22.5306 129.596 22.808 129.168 22.9979C128.741 23.1877 128.279 23.2858 127.811 23.2858C127.343 23.2858 126.881 23.1877 126.453 22.9979C126.026 22.808 125.643 22.5306 125.329 22.1835C125.014 21.8363 124.776 21.4271 124.629 20.9821C124.482 20.5372 124.429 20.0663 124.475 19.5997ZM129.711 19.5997C129.711 18.0624 129.022 17.1634 127.813 17.1634C126.6 17.1634 125.917 18.0624 125.917 19.5997C125.917 21.1494 126.6 22.0414 127.813 22.0414C129.022 22.0414 129.711 21.1432 129.711 19.5997Z" fill="#263238"/>
<path d="M133.021 16.0579H134.365V17.1846H134.47C134.647 16.7799 134.945 16.4407 135.324 16.2142C135.702 15.9877 136.142 15.8852 136.581 15.921C136.925 15.8951 137.271 15.9471 137.592 16.0733C137.914 16.1994 138.203 16.3965 138.438 16.6498C138.673 16.9031 138.848 17.2063 138.951 17.5367C139.053 17.8672 139.08 18.2165 139.03 18.5589V23.15H137.633V18.9103C137.633 17.7706 137.139 17.2038 136.107 17.2038C135.873 17.1929 135.64 17.2328 135.423 17.3207C135.206 17.4086 135.011 17.5426 134.85 17.7133C134.69 17.884 134.568 18.0874 134.493 18.3097C134.419 18.532 134.393 18.7678 134.418 19.0011V23.1501H133.021V16.0579Z" fill="#263238"/>
<path d="M146.917 14.291V16.089H148.45V17.268H146.917V20.9148C146.917 21.6577 147.223 21.983 147.918 21.983C148.095 21.9824 148.273 21.9716 148.45 21.9507V23.1165C148.199 23.1615 147.945 23.1854 147.69 23.1881C146.138 23.1881 145.52 22.6405 145.52 21.2731V17.2679H144.396V16.089H145.52V14.291H146.917Z" fill="#263238"/>
<path d="M150.357 13.2891H151.741V17.1973H151.852C152.037 16.7889 152.344 16.4478 152.73 16.2204C153.116 15.9931 153.563 15.8906 154.009 15.9269C154.351 15.9082 154.694 15.9655 155.011 16.0947C155.329 16.224 155.615 16.4219 155.847 16.6744C156.08 16.927 156.254 17.2279 156.358 17.5557C156.461 17.8835 156.491 18.2301 156.445 18.5709V23.1497H155.048V18.9161C155.048 17.7833 154.521 17.2096 153.535 17.2096C153.295 17.1899 153.054 17.223 152.828 17.3065C152.602 17.3901 152.397 17.5221 152.227 17.6934C152.058 17.8646 151.927 18.071 151.845 18.298C151.764 18.525 151.732 18.7672 151.754 19.0077V23.1497H150.357L150.357 13.2891Z" fill="#263238"/>
<path d="M164.586 21.2341C164.396 21.8823 163.985 22.443 163.425 22.8181C162.865 23.1931 162.191 23.3588 161.521 23.2859C161.055 23.2983 160.592 23.2088 160.163 23.0237C159.735 22.8386 159.352 22.5623 159.042 22.214C158.731 21.8657 158.499 21.4537 158.363 21.0067C158.227 20.5597 158.189 20.0883 158.253 19.6253C158.191 19.1608 158.229 18.6885 158.365 18.2401C158.501 17.7917 158.731 17.3779 159.04 17.0265C159.35 16.6752 159.731 16.3946 160.158 16.2037C160.584 16.0128 161.047 15.916 161.515 15.92C163.483 15.92 164.671 17.2682 164.671 19.4953V19.9837H159.675V20.0621C159.653 20.3224 159.686 20.5844 159.771 20.8312C159.856 21.0781 159.991 21.3045 160.169 21.4957C160.346 21.687 160.562 21.8391 160.801 21.9421C161.04 22.0451 161.299 22.0968 161.559 22.0939C161.893 22.1341 162.231 22.0738 162.531 21.9207C162.831 21.7676 163.078 21.5286 163.242 21.2341L164.586 21.2341ZM159.675 18.9485H163.248C163.266 18.7105 163.234 18.4714 163.154 18.2466C163.073 18.0219 162.947 17.8164 162.783 17.6433C162.619 17.4703 162.421 17.3336 162.201 17.242C161.981 17.1503 161.745 17.1058 161.507 17.1113C161.265 17.1083 161.026 17.1537 160.802 17.245C160.578 17.3362 160.375 17.4715 160.205 17.6427C160.034 17.8139 159.899 18.0177 159.808 18.2419C159.717 18.4662 159.672 18.7065 159.675 18.9485H159.675Z" fill="#263238"/>
</g>
<defs>
<clipPath id="clip0_1294:4857">
<rect width="188" height="63" fill="white"/>
</clipPath>
</defs>
</svg>




</template>
<script>
export default {
    name: 'Download_AppStore',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>

<style scoped>
_::-webkit-full-page-media, _:future, :root svg {
    margin-right: 20px;
}
</style>