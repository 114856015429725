<template>
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 10.7734L11.7735 0.773437L0.226497 0.773438L6 10.7734ZM5 0.773438V1.77344H7V0.773437L5 0.773438Z"
      fill="#263238"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowFooter",
  data() {
    return {};
  },
  props: {
    fill: {
      default: "--color-primary",
    },
  },
};
</script>