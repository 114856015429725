<template>
  <div class="footer-container">
    <div class="first-section overflow-x-hidden">
      <div class="square one"></div>
      <div class="square two"></div>
      <div class="square three"></div>
      <div class="square four"></div>
      <div class="square five"></div>
      <div class="square six"></div>
      <div class="square seven"></div>
      <div class="square eight"></div>
      <div class="square nine"></div>
      <div class="square ten"></div>
      <div class="square eleven"></div>
      <div class="square twelve"></div>
      <div class="square thirteen"></div>
      <div class="square fourteen"></div>
      <div class="square fifteen"></div>
      <div class="square sixteen"></div>
      <div class="square seventeen"></div>
      <div class="square heighteen"></div>
      <h2 class="headline">{{ $t("footer.title") }}</h2>
      <div class="button-box">
        <div>
          <router-link to="/startnow">
            <button class="main-button">
              {{ $t("footer.subtitle") }}
            </button></router-link
          >
          <div class="credit-card">
            <check-mark style="fill: white" />
            <span style="margin-left: 10px">{{ $t("home.credit_card") }}</span>
          </div>
        </div>
        <router-link to="/contactus"
          ><button class="second-button">
            {{ $t("footer.button") }}
          </button></router-link
        >
      </div>
    </div>
    <div class="second-section web">
      <div class="second-first">
        <div style="width: 25%">
          <router-link :to="'/'"><n-symbol class="n-symbol" /></router-link>
        </div>
        <div class="nav-1">
          <div class="nav-bar">
            <span class="nav-item first">{{ $t("footer.company") }}</span>
            <router-link to="/aboutus"
              ><span class="nav-item">{{
                $t("navbar.about")
              }}</span></router-link
            >
            <router-link to="/contactus"
              ><span class="nav-item">{{
                $t("navbar.contactus")
              }}</span></router-link
            >
            <router-link to="/press"
              ><span class="nav-item">{{
                $t("navbar.press")
              }}</span></router-link
            >
          </div>
        </div>
        <div class="nav-2">
          <div class="nav-bar">
            <span class="nav-item first">{{ $t("footer.product") }}</span>
            <router-link to="/features"
              ><span class="nav-item">{{
                $t("navbar.features")
              }}</span></router-link
            >
            <router-link to="/pricing"
              ><span class="nav-item">{{
                $t("navbar.pricing")
              }}</span></router-link
            >
            <router-link to="/support"
              ><span class="nav-item">{{
                $t("navbar.support")
              }}</span></router-link
            >
          </div>
        </div>
        <div class="nav-2">
          <div class="nav-bar">
            <span class="nav-item first">{{ $t("footer.policies") }}</span>
            <router-link to="/terms"
              ><span class="nav-item">{{
                $t("footer.terms_use")
              }}</span></router-link
            >
            <router-link to="/privacy"
              ><span class="nav-item">{{
                $t("footer.privacy_policy")
              }}</span></router-link
            >
            <router-link to="/processing"
              ><span class="nav-item">{{
                $t("footer.data_processing")
              }}</span></router-link
            >
            <router-link to="/cookies"
              ><span class="nav-item">{{
                $t("footer.cookies_policy")
              }}</span></router-link
            >
          </div>
        </div>
        <div>
          <span class="first">DOWNLOAD NOW</span>
          <div class="flex flex-row gap-4 mt-4">
            <a
              rel="noreferrer"
              alt=""
              target="_blank"
              href="https://apps.apple.com/pt/app/napps/id1557222449?l=en"
            >
              <app-store />
            </a>
            <a
              rel="noreferrer"
              alt=""
              target="_blank"
              href="https://play.google.com/store/apps/details?id=pt.napps.shop"
            >
              <google-play />
            </a>
          </div>
        </div>
      </div>
      <div class="second-second">
        <div class="language">
          <span class="mr-4">{{ $t("footer.choose_language") }}</span>
          <div class="mr-2 language-container">
            <span v-if="$i18n.locale == 'en'">English ( United Kingdom )</span>
            <span v-else>Português ( Portugal )</span>
            <arrow-footer class="arrow-down" />
            <div id="language-options" class="">
              <div class="lang-inner" role="listbox">
                <a
                  role="option"
                  aria-label="Change language to English"
                  @click="changeLang('en')"
                  class="lang-item"
                  >{{ $t("footer.english") }}</a
                >
                <a
                  role="option"
                  aria-label="Change language to Portuguese"
                  @click="changeLang('pt')"
                  class="lang-item"
                  >{{ $t("footer.portuguese") }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="social">
          <div class="social-item">{{ $t("footer.follow_us") }}:</div>
          <a
            rel="noreferrer"
            alt=""
            aria-label="Instagram NAPPS"
            href="https://www.instagram.com/napps.pt/?hl=pt"
            target="_blank"
          >
            <div class="social-item">
              <social-instagram />
            </div>
          </a>
          <a
            rel="noreferrer"
            alt=""
            aria-label="Facebook NAPPS"
            href="https://www.facebook.com/napps.pt"
            target="_blank"
          >
            <div class="social-item">
              <social-facebook />
            </div>
          </a>
          <a
            rel="noreferrer"
            alt=""
            aria-label="LinkedIn NAPPS"
            href="https://www.linkedin.com/company/71240104/"
            target="_blank"
          >
            <div class="social-item">
              <social-linkedin />
            </div>
          </a>
        </div>
      </div>
      <div
        class="
          font-gothambook
          text-mainBrand
          flex flex-col
          items-center
          md:flex-row md:justify-between
          gap-4
          py-6
        "
      >
        <div class="">
          © {{ new Date().getFullYear() }} NAPPS - All Rights Reserved
        </div>
        <a
          rel="noreferrer"
          alt=""
          aria-label="Complaint Book"
          target="_blank"
          href="https://www.livroreclamacoes.pt/inicio"
          >
            <img
              class="max-h-12"
              aria-label="Complaint Book"
              loading="lazy"
              alt="livro-de-reclamações"
              src="https://d3dx13u2x4z410.cloudfront.net/livro-reclamacoes.png"
            />
        </a>
      </div>
    </div>
    <div class="second-section mobile">
      <div class="second-first">
        <div class="col-logo">
          <router-link aria-label="Back to home" :to="'/'"
            ><n-symbol class="n-symbol"
          /></router-link>
        </div>
        <div class="download-mobile">
          <span class="first">DOWNLOAD NOW</span>
          <div class="flex flex-row gap-4 mt-4">
            <a
              alt=""
              rel="noreferrer"
              target="_blank"
              href="https://apps.apple.com/pt/app/napps/id1557222449?l=en"
            >
              <app-store class="apple-logo" />
            </a>
            <a
              alt=""
              rel="noreferrer"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=pt.napps.shop"
            >
              <google-play class="play-logo" />
            </a>
          </div>
        </div>
      </div>
      <div class="second-second">
        <div class="nav-1">
          <div class="nav-bar">
            <span class="nav-item first">{{ $t("footer.company") }}</span>
            <router-link to="/aboutus"
              ><span class="nav-item">{{
                $t("navbar.about")
              }}</span></router-link
            >
            <router-link to="/contactus"
              ><span class="nav-item">{{
                $t("navbar.contactus")
              }}</span></router-link
            >
          </div>
        </div>
        <div class="nav-2">
          <div class="nav-bar">
            <span class="nav-item first">{{ $t("footer.product") }}</span>
            <router-link to="/features"
              ><span class="nav-item">{{
                $t("navbar.features")
              }}</span></router-link
            >
            <router-link to="/pricing"
              ><span class="nav-item">{{
                $t("navbar.pricing")
              }}</span></router-link
            >
            <router-link to="/support"
              ><span class="nav-item">{{
                $t("navbar.support")
              }}</span></router-link
            >
          </div>
        </div>
        <div class="nav-2">
          <div class="nav-bar">
            <span class="nav-item first">{{ $t("footer.policies") }}</span>
            <router-link to="/terms"
              ><span class="nav-item">{{
                $t("footer.terms_use")
              }}</span></router-link
            >
            <router-link to="/privacy"
              ><span class="nav-item">{{
                $t("footer.privacy_policy")
              }}</span></router-link
            >
            <router-link to="/processing"
              ><span class="nav-item">{{
                $t("footer.data_processing")
              }}</span></router-link
            >
            <router-link to="/cookies"
              ><span class="nav-item">{{
                $t("footer.cookies_policy")
              }}</span></router-link
            >
          </div>
        </div>
      </div>
      <div class="second-third">
        <div class="social-item">{{ $t("footer.follow_us") }}:</div>
        <div class="social-icons flex flex-row gap-6">
          <a
            alt=""
            rel="noreferrer"
            aria-label="Instagram NAPPS"
            href="https://www.instagram.com/napps.pt/?hl=pt"
            target="_blank"
          >
            <div class="social-item">
              <social-instagram />
            </div>
          </a>
          <a
            alt=""
            rel="noreferrer"
            aria-label="Facebook NAPPS"
            href="https://www.facebook.com/napps.pt"
            target="_blank"
          >
            <div class="social-item">
              <social-facebook />
            </div>
          </a>
          <a
            alt=""
            rel="noreferrer"
            aria-label="LinkedIn NAPPS"
            href="https://www.linkedin.com/company/71240104/"
            target="_blank"
          >
            <div class="social-item">
              <social-linkedin />
            </div>
          </a>
        </div>
      </div>
      <div
        class="
          font-gothambook
          text-mainBrand
          flex flex-col
          items-center
          md:flex-row md:justify-between
          gap-4
          py-6
        "
      >
        <div class="">
          © {{ new Date().getFullYear() }} NAPPS - All Rights Reserved
        </div>
        <a
          alt=""
          rel="noreferrer"
          aria-label="Complaint Book"
          target="_blank"
          href="https://www.livroreclamacoes.pt/inicio"
          ><img
            class="max-h-12"
            aria-label="Complaint Book"
            loading="lazy"
            alt="livro-de-reclamações"
            src="https://d3dx13u2x4z410.cloudfront.net/livro-reclamacoes.png"
        /></a>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import CheckMark from "@/components/Icons/CheckMark.vue";
import NSymbol from "@/components/Icons/NSymbol.vue";
import AppStore from "./Logos/Download_AppStore.vue";
import GooglePlay from "./Logos/Download_GooglePlay.vue";
import SocialInstagram from "./Logos/Social_Instagram2.vue";
import SocialFacebook from "./Logos/Social_Facebook2.vue";
import SocialLinkedin from "./Logos/Social_Linkedin2.vue";
import ArrowFooter from "../Icons/ArrowFooter.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    AppStore,
    GooglePlay,
    SocialInstagram,
    SocialFacebook,
    SocialLinkedin,
    CheckMark,
    NSymbol,
    ArrowFooter,
  },
  props: {},
  methods: {
    changeLang(lang) {
      localStorage.setItem('locale', lang)
      this.$i18n.locale = lang;
      document.documentElement.lang = lang;
    },
  },
  data() {
    return {};
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
.language-container {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#language-options {
  min-width: 12.5rem;
  position: absolute;
  left: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 50px;
  box-shadow: 0.0375rem 0.1575rem 0.525rem 0.0125rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  transition: max-height 0.35s ease-out;
  opacity: 1;
  overflow: hidden;
  max-height: 0;
}

.language-container:hover #language-options {
  opacity: 1;
  max-height: 537px;
}

.language-container:hover .arrow-down {
  transform: rotate(180deg);
}

.lang-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lang-item {
  padding: 10px;
}

.lang-item:hover {
  color: #263238;
  text-decoration: underline;
  cursor: pointer;
}

.arrow-down {
  transition: transform 250ms ease-in-out;
  transform: rotate(0deg);
  margin-left: 10px;
}

.col-logo {
  width: 50%;
}

.n-symbol {
  max-height: 140px;
}
.footer-container {
  height: fit-content;
  /* margin-top: 100px; */
}

.livro-reclamacoes {
  margin-left: auto;
  object-fit: contain;
  max-width: 120px;
}
.copyright {
  color: #263238;
  font-family: GothamBook;
  line-height: 40px;
  max-width: 25%;
  text-align: center;
}

.first-section {
  position: relative;
  background-color: #263238;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.second-section {
  display: flex;
  flex-direction: column;
  padding: 100px;
  width: 100%;
  padding-bottom: 0px;
}

.second-first {
  display: flex;
  flex-direction: row;
}

.second-second {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  align-items: center;
  gap: 20px;
}

.second-third {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  align-items: center;
  border-top: 1px solid #dedede;
  padding: 15px 0px;
}

.social {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
}

.social-item {
  font-family: GothamMedium, "sans-serif" !important;
  text-transform: uppercase;
  font-size: 18px;
}

.language {
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: GothamBook;
  font-size: 18px;
  position: relative;
}

.nav-1 {
  width: 20%;
}

.nav-2 {
  width: 20%;
}

.nav-bar {
  display: flex;
  flex-direction: column;
}

.first {
  font-family: GothamMedium, "sans-serif" !important;
  margin-bottom: 6px;
  text-transform: uppercase;
  font-size: 20px !important;
}

.nav-item {
  font-family: GothamBook, "sans-serif";
  margin-top: 6px;
  font-size: 18px;
}

.nav-bar > a {
  margin-top: 5px;
}

.headline {
  color: white;
  font-family: GothamBold, "sans-serif";
  font-size: 40px;
  text-align: center;
}

.button-box {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
}

.main-button {
  background-color: white;
  padding: 12px;
  color: #263238;
  border-radius: 5px;
  font-family: GothamBook, "sans-serif";
  margin-right: 25px;
  width: 380px;
  text-transform: uppercase;
  border: 1px solid white;
  margin-top: 30px;
}

.main-button:hover {
  opacity: 0.8;
}

.second-button {
  color: white;
  border: 1px solid white;
  padding: 12px;
  border-radius: 5px;
  font-family: GothamBook, "sans-serif";
  margin-left: 25px;
  width: 380px;
  text-transform: uppercase;
  transition: 0.3s all ease-in-out;
}

.second-button:hover {
  color: #263238;
  background-color: white;
}

.info-bottom {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

.credit-card {
  color: white;
  font-family: GothamBook, "sans-serif";
  text-align: center;
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 20px;
  margin-top: 10px;
}

.square {
  border: 1px solid #ffffffa2;
  border-radius: 4px;
  position: absolute;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 1s;
  opacity: 1;
}

.one {
  height: 80px;
  width: 80px;
  top: 7%;
  left: 19%;
  transform: rotate(20deg);
}

.two {
  height: 100px;
  width: 100px;
  top: 33%;
  left: 80%;
  transform: rotate(20deg);
}

.three {
  height: 50px;
  width: 50px;
  top: 85%;
  left: 0%;
  transform: rotate(20deg);
}

.four {
  height: 50px;
  width: 50px;
  top: 68%;
  left: 15%;
  transform: rotate(20deg);
}

.five {
  height: 10px;
  width: 10px;
  top: 85%;
  left: 49%;
  transform: rotate(20deg);
  border-radius: 3px;
}

.six {
  height: 10px;
  width: 10px;
  top: 93%;
  left: 65%;
  transform: rotate(20deg);
  border-radius: 3px;
}

.six {
  height: 10px;
  width: 10px;
  top: 93%;
  left: 65%;
  transform: rotate(20deg);
  border-radius: 3px;
}

.seven {
  height: 60px;
  width: 60px;
  top: 75%;
  left: 95%;
  transform: rotate(20deg);
}

.eight {
  height: 20px;
  width: 20px;
  top: 20%;
  left: 60%;
  transform: rotate(20deg);
}

.nine {
  height: 30px;
  width: 30px;
  top: 12%;
  left: 5%;
  transform: rotate(20deg);
}

.ten {
  height: 40px;
  width: 40px;
  top: 8%;
  right: 5%;
  transform: rotate(20deg);
}
@media only screen and (min-width: 1024px) {
  .mobile {
    display: none;
  }
  .footer-container {
    position: relative;
  }
}

@media only screen and (max-width: 1441px) {
  .nav-item {
    font-size: 18px;
  }

  .social-item {
    font-size: 16px;
  }
  .copyright {
    max-width: 35%;
  }
  .livro-reclamacoes {
    max-width: 12%;
  }
  .social-item {
    margin-bottom: 20px;
  }
  .main-button {
    width: 350px;
  }
  .second-button {
    width: 350px;
  }
  .second-section {
    padding: 50px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 1260px) {
  .main-button {
    width: 350px;
  }
  .second-button {
    width: 300px;
  }
  .credit-card {
    width: 100%;
  }
  .headline {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1023px) {
  .second-button {
    width: 350px;
  }
  .main-button {
    width: 350px;
  }
  .credit-card {
    width: 100%;
  }
  .copyright {
    width: 55%;
  }
  .livro-reclamacoes {
    max-width: 30%;
  }
  .second-section {
    padding: 20px;
  }
  .copyright {
    max-width: 45%;
  }
  .first-section {
    overflow: hidden;
  }
  .web {
    display: none;
  }
  .second-second {
    align-items: flex-start;
  }
  .nav-1 {
    width: 33%;
  }
  .nav-2 {
    width: 33%;
  }
  .second-fourfh {
    display: flex;
    align-items: center;
  }
  .social-item {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 760px) {
  .button-box {
    flex-direction: column;
  }
  .main-button {
    width: 350px;
    margin-right: 0px;
  }
  .second-button {
    margin-top: 30px;
    width: 350px;
    margin-left: 0px;
  }
  .headline {
    font-size: 35px;
  }
  .first-section {
    height: fit-content;
    padding: 50px 20px;
  }
  .credit-card {
    width: 100%;
    margin: auto;
    margin-top: 11px;
  }
  .square {
    display: none;
  }
  .social {
    width: 50%;
    align-items: flex-start;
  }
  .nav-1 {
    width: 50%;
  }
  .nav-2 {
    width: 50%;
  }
  .second-second {
    align-items: self-start;
    margin-top: 20px;
  }
  .second-section {
    padding-top: 50px;
    padding-bottom: 50px;
    padding: 20px;
  }
  .second-third {
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
  }
  .second-fourfh {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .livro-reclamacoes {
    margin: auto;
  }
  .copyright {
    max-width: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 540px) {
  .social-icons {
    gap: 10px;
  }
  .n-symbol {
    max-width: 100px;
    max-height: 100px;
  }
  .download-mobile {
    margin: 0px;
    margin-left: 30px;
  }
  .download-mobile > div {
    margin-top: 0px;
  }
  .col-logo {
    width: 120px;
  }
  .apple-logo {
    max-width: 100%;
  }
  .play-logo {
    max-width: 100%;
  }
  .social-item {
    font-size: 16px;
    gap: 5px !important;
    margin-bottom: 0px;
  }
  .social-item > svg {
    width: 35px;
  }
  .nav-item {
    font-size: 16px;
  }
  .second-third > div > svg {
    max-width: 100%;
  }
  .livro-reclamacoes {
    max-width: 25%;
  }
  .second-first > div > svg {
    max-height: 120px;
    max-width: 60%;
  }
  .copyright {
    font-size: 14px;
  }
  .main-button {
    width: 340px;
  }
  .second-button {
    width: 340px;
  }
  .second-second {
    flex-wrap: wrap;
  }
  .nav-1 {
    width: 29%;
  }
  .nav-2 {
    width: 29%;
  }
  .second-fourfh {
    border-top: 1px solid #dedede;
    flex-direction: column-reverse;
    margin: 0px;
    gap: 20px;
    padding-top: 20px;
    margin-top: 10px;
  }
  .second-third {
    margin-top: 0px;
    align-items: flex-start;
    border-top: none;
  }
  .headline {
    font-size: 30px;
  }
}
@media only screen and (max-width: 370px) {
  .mobile-buttons {
    flex-direction: column;
    gap: 0px;
    justify-content: center;
  }

  .main-button {
    width: 290px;
    font-size: 14px;
    padding: 12px 10px;
  }

  .second-button {
    width: 280px;
    font-size: 14px;
    padding: 12px 30px;
  }
}
</style>