<template>
<svg width="216" height="60" viewBox="0 0 216 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M75.5528 43.0359V39.1013C73.8048 40.5776 71.8073 41.7299 69.6544 42.5042C67.4462 43.299 65.1158 43.7005 62.7689 43.6905C59.9241 43.7124 57.106 43.1408 54.4945 42.0124C51.977 40.9428 49.6961 39.3856 47.7832 37.4305C45.8703 35.4754 44.3632 33.1611 43.3489 30.6208C42.248 27.93 41.6912 25.0476 41.7106 22.1403C41.6886 19.2457 42.2456 16.3759 43.3489 13.6997C44.3841 11.166 45.9008 8.85704 47.8151 6.90071C49.7472 4.95472 52.044 3.4088 54.5742 2.35141C59.3356 0.345032 64.6616 0.126702 69.5713 1.73664C71.6817 2.43722 73.6713 3.45948 75.4697 4.76729V1.3279H83.6645V43.0359H75.5528ZM49.7425 22.0606C49.7425 26.1546 51.1083 29.5951 53.8398 32.3821C56.5714 35.169 59.931 36.5625 63.9187 36.5625C66.1573 36.5851 68.3759 36.1391 70.432 35.2532C72.3687 34.4207 74.0912 33.1595 75.4697 31.5646V12.6231C74.062 11.0635 72.3285 9.83257 70.3921 9.01751C68.3478 8.13868 66.1439 7.6918 63.9187 7.70489C59.931 7.70489 56.5714 9.07068 53.8398 11.8023C51.1083 14.5338 49.7425 17.9533 49.7425 22.0606Z" fill="#284452"/>
<path d="M90.4639 59.9996V1.32726H98.5755V5.34155C100.27 3.81301 102.246 2.63001 104.394 1.85895C109.357 0.11856 114.79 0.293957 119.631 2.35077C124.7 4.51078 128.708 8.59124 130.776 13.6991C131.879 16.3753 132.436 19.2451 132.414 22.1397C132.434 25.047 131.877 27.9293 130.776 30.6202C129.19 34.5048 126.477 37.8255 122.987 40.1548C119.497 42.4841 115.389 43.7153 111.193 43.6899C108.924 43.6937 106.669 43.3347 104.514 42.6265C102.408 41.9433 100.428 40.9183 98.6553 39.5925V59.9996H90.4639ZM110.293 7.80062C108.042 7.78208 105.811 8.22779 103.74 9.10992C101.786 9.93502 100.048 11.1969 98.6586 12.7985V31.6637C100.035 33.2404 101.76 34.4749 103.696 35.2692C105.783 36.1501 108.028 36.5957 110.293 36.5785C114.28 36.5785 117.627 35.2127 120.332 32.4811C123.037 29.7496 124.389 26.3357 124.389 22.2394C124.389 18.1432 123.023 14.7148 120.292 11.9545C119 10.6116 117.446 9.54967 115.725 8.83495C114.005 8.12022 112.155 7.7681 110.293 7.80062Z" fill="#284452"/>
<path d="M137.334 60.0002V1.3279H145.446V5.34218C147.139 3.81295 149.116 2.62987 151.264 1.85959C156.228 0.118338 161.663 0.293741 166.504 2.35141C171.574 4.51142 175.581 8.59188 177.65 13.6997C178.753 16.3759 179.31 19.2457 179.288 22.1403C179.307 25.0476 178.75 27.93 177.65 30.6208C176.063 34.5059 173.35 37.8271 169.859 40.1564C166.368 42.4858 162.26 43.7166 158.063 43.6905C155.796 43.6943 153.542 43.3353 151.387 42.6271C149.281 41.9446 147.302 40.9196 145.529 39.5932V60.0002H137.334ZM157.179 7.80126C154.927 7.78274 152.695 8.22843 150.623 9.11055C148.671 9.93579 146.934 11.1977 145.545 12.7992V31.6643C146.919 33.233 148.638 34.4615 150.566 35.2532C152.658 36.1369 154.909 36.5825 157.179 36.5625C161.167 36.5625 164.513 35.1967 167.218 32.4652C169.923 29.7336 171.275 26.3197 171.273 22.2234C171.273 18.1272 169.907 14.6989 167.175 11.9385C165.883 10.5984 164.329 9.53936 162.609 8.82749C160.889 8.11562 159.041 7.76626 157.179 7.80126Z" fill="#284452"/>
<path d="M180.272 37.6454L184.695 32.2354C186.728 33.8403 188.976 35.1503 191.375 36.1268C193.575 37.0166 195.926 37.4758 198.3 37.4793C201.249 37.4793 203.653 36.9066 205.511 35.7612C207.37 34.6159 208.298 33.1127 208.296 31.2518C208.32 30.5771 208.184 29.9063 207.899 29.2942C207.614 28.682 207.189 28.1458 206.658 27.7293C205.566 26.8565 203.872 26.255 201.577 25.9249L194.037 24.8615C189.94 24.2589 186.839 22.9884 184.732 21.05C182.625 19.1115 181.573 16.5306 181.575 13.3072C181.575 9.54104 183.077 6.49488 186.081 4.16872C189.085 1.84257 193.019 0.681704 197.881 0.686134C200.842 0.671668 203.786 1.11443 206.611 1.99875C209.485 2.93181 212.205 4.28452 214.683 6.01303L210.509 11.3931C208.449 9.97934 206.2 8.86081 203.83 8.07002C201.724 7.39637 199.527 7.05116 197.317 7.04651C194.802 7.04651 192.781 7.56491 191.252 8.60171C189.723 9.63851 188.958 11.012 188.956 12.7223C188.956 14.2532 189.488 15.414 190.554 16.2049C191.62 16.9958 193.382 17.584 195.841 17.9695L203.371 19.0362C207.465 19.6365 210.592 20.9204 212.752 22.8876C214.912 24.8549 215.994 27.448 215.999 30.667C216.008 32.4886 215.542 34.2811 214.646 35.8676C213.73 37.4977 212.472 38.9102 210.958 40.0081C209.276 41.2172 207.408 42.1443 205.428 42.753C203.201 43.4492 200.879 43.7946 198.546 43.7765C195.108 43.8027 191.688 43.2765 188.417 42.218C185.42 41.2618 182.649 39.7061 180.272 37.6454Z" fill="#284452"/>
<path d="M8.19471 9.52295H0V43.0362H8.19471V9.52295Z" fill="#284452"/>
<path d="M28.8439 1.32837H16.3891C14.2157 1.32837 12.1313 2.19174 10.5945 3.72855C9.05771 5.26535 8.19434 7.34971 8.19434 9.52308H28.8439V43.0363H37.0387V1.32837H28.8439Z" fill="#284452"/>
</svg>

</template>
<script>
export default {
    name: 'NappsBlue',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>