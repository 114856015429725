<template>
  <svg width="712" height="196" viewBox="0 0 712 196" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M249.019 139.64V126.672C243.257 131.537 236.673 135.336 229.577 137.887C222.299 140.507 214.618 141.831 206.882 141.798C197.506 141.87 188.217 139.986 179.61 136.266C171.312 132.741 163.794 127.609 157.489 121.165C151.184 114.72 146.216 107.092 142.873 98.7195C139.245 89.8503 137.409 80.35 137.473 70.7674C137.401 61.2267 139.237 51.7677 142.873 42.9468C146.285 34.5955 151.285 26.9851 157.594 20.5369C163.962 14.1229 171.533 9.02747 179.872 5.54227C195.566 -1.07085 213.121 -1.79048 229.303 3.51595C236.259 5.82508 242.817 9.19449 248.745 13.5051V2.16872H275.755V139.64H249.019ZM163.947 70.5046C163.947 83.9987 168.448 95.3387 177.452 104.525C186.455 113.711 197.529 118.304 210.672 118.304C218.051 118.378 225.363 116.908 232.14 113.988C238.524 111.244 244.201 107.087 248.745 101.83V39.398C244.105 34.2577 238.391 30.2005 232.009 27.514C225.271 24.6174 218.006 23.1444 210.672 23.1876C197.529 23.1876 186.455 27.6893 177.452 36.6926C168.448 45.696 163.947 56.9667 163.947 70.5046Z" fill="white"/>
<path d="M298.173 195.553V2.16661H324.91V15.3979C330.494 10.3597 337.009 6.46053 344.088 3.9191C360.445 -1.81731 378.355 -1.2392 394.308 5.54016C411.018 12.6596 424.227 26.109 431.044 42.9447C434.681 51.7656 436.517 61.2246 436.444 70.7653C436.508 80.3479 434.673 89.8482 431.044 98.7174C425.816 111.521 416.874 122.467 405.37 130.144C393.867 137.822 380.328 141.88 366.498 141.796C359.02 141.808 351.587 140.625 344.483 138.291C337.541 136.039 331.017 132.66 325.173 128.291V195.553H298.173ZM363.53 23.5031C356.11 23.442 348.757 24.9111 341.931 27.8186C335.493 30.5382 329.763 34.6973 325.183 39.9764V102.157C329.72 107.354 335.405 111.423 341.788 114.041C348.666 116.944 356.064 118.413 363.53 118.356C376.674 118.356 387.703 113.854 396.619 104.851C405.535 95.8477 409.993 84.5954 409.993 71.0939C409.993 57.5925 405.491 46.2927 396.488 37.1944C392.231 32.7684 387.107 29.268 381.436 26.9123C375.766 24.5565 369.67 23.3959 363.53 23.5031Z" fill="white"/>
<path d="M452.661 195.555V2.16872H479.397V15.4C484.981 10.3596 491.496 6.46007 498.576 3.92121C514.936 -1.81804 532.85 -1.23991 548.807 5.54226C565.517 12.6618 578.726 26.1111 585.543 42.9468C589.179 51.7677 591.015 61.2267 590.943 70.7674C591.007 80.35 589.172 89.8503 585.543 98.7195C580.314 111.525 571.37 122.472 559.864 130.149C548.359 137.827 534.818 141.884 520.986 141.798C513.511 141.81 506.082 140.627 498.981 138.293C492.039 136.043 485.515 132.665 479.671 128.293V195.555H452.661ZM518.072 23.5052C510.649 23.4442 503.293 24.9132 496.462 27.8207C490.027 30.5407 484.302 34.6999 479.726 39.9785V102.159C484.253 107.329 489.918 111.378 496.276 113.988C503.17 116.901 510.588 118.37 518.072 118.304C531.216 118.304 542.246 113.802 551.162 104.798C560.077 95.7951 564.531 84.5427 564.524 71.0413C564.524 57.5399 560.023 46.24 551.019 37.1417C546.761 32.7249 541.637 29.2341 535.968 26.8877C530.299 24.5413 524.207 23.3898 518.072 23.5052Z" fill="white"/>
<path d="M594.187 121.869L608.765 104.038C615.464 109.327 622.875 113.645 630.781 116.864C638.034 119.797 645.783 121.31 653.607 121.322C663.326 121.322 671.248 119.434 677.375 115.659C683.501 111.884 686.561 106.929 686.553 100.796C686.632 98.5719 686.184 96.361 685.245 94.3433C684.307 92.3256 682.905 90.5583 681.153 89.1856C677.554 86.3086 671.971 84.3261 664.406 83.2381L639.554 79.7331C626.052 77.747 615.83 73.5593 608.885 67.17C601.941 60.7808 598.473 52.2739 598.48 41.6495C598.48 29.2361 603.431 19.1958 613.332 11.5287C623.234 3.86162 636.199 0.0353558 652.227 0.0499598C661.983 0.00227725 671.689 1.46164 681 4.37641C690.472 7.4518 699.437 11.9104 707.605 17.6076L693.848 35.3406C687.056 30.6808 679.646 26.994 671.832 24.3876C664.892 22.1672 657.651 21.0294 650.365 21.014C642.077 21.014 635.414 22.7227 630.375 26.14C625.337 29.5574 622.814 34.0846 622.807 39.7218C622.807 44.7675 624.563 48.5937 628.075 51.2005C631.587 53.8074 637.396 55.746 645.501 57.0166L670.321 60.5325C683.815 62.5113 694.122 66.7429 701.241 73.227C708.361 79.7112 711.928 88.2582 711.942 98.868C711.972 104.872 710.435 110.78 707.484 116.01C704.464 121.383 700.316 126.038 695.327 129.657C689.783 133.642 683.627 136.698 677.101 138.704C669.761 140.999 662.108 142.137 654.417 142.078C643.086 142.164 631.814 140.43 621.032 136.941C611.154 133.789 602.021 128.661 594.187 121.869Z" fill="white"/>
<path d="M27.0101 29.1846H0V139.646H27.0101V29.1846Z" fill="white"/>
<path d="M95.0757 2.16699H54.0238C46.8603 2.16699 39.9901 5.0127 34.9248 10.0781C29.8594 15.1435 27.0137 22.0136 27.0137 29.1771H95.0757V139.638H122.086V2.16699H95.0757Z" fill="white"/>
</svg>
</template>
<script>
export default {
    name: 'LogoWhite',
    data() {
        return {}
    },
    props: {
        fill: {
            default: '--color-primary'
        }
    },
}
</script>