<template>
  <svg
    width="177"
    height="179"
    viewBox="0 0 177 179"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M117.736 0C101.987 0.0616647 86.9052 6.36212 75.7962 17.5206C64.6871 28.679 58.4576 43.7848 58.4732 59.5267H117.736V178.58H177V0H117.736Z"
      fill="#263238"
    />
    <path d="M58.4732 60.0536H0V178.58H58.4732V60.0536Z" fill="#263238" />
  </svg>
</template>
<script>
export default {
  name: "NSymbol",
  data() {
    return {};
  },
  props: {
    fill: {
      default: "--color-primary",
    },
  },
};
</script>